import React, { useEffect } from "react";
import LoadingIndication from "./components/LoadingIndication";
import { redirectToPage } from "./utils/redirectToPage";
import { withLDProvider } from "launchdarkly-react-client-sdk";

function App() {
  useEffect(() => {
    setTimeout(async () => {
      redirectToPage();
    }, 1000);
  }, []);

  return (
    <div className="container">
      <div className="text">
        Checkout is completed, get ready to meet your expert!
      </div>
      <LoadingIndication />
    </div>
  );
}
const queryParams = new URLSearchParams(window.location.search);

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_ID as string,
  context: {
    kind: "user",
    key: queryParams.get("uuid") || "",
    email: queryParams.get("email"),
  },
})(App);
