import React from "react";
import loaderAnimation from "./loader_animation.json";
import Lottie from "lottie-react";

const LoadingIndication = () => {
  return (
    <div className="loader">
      <Lottie animationData={loaderAnimation} loop={true} />
    </div>
  );
};

export default LoadingIndication;
